require('./bootstrap')
import { createApp, h } from '../node_modules/vue'
import { createInertiaApp, Head, Link } from '@inertiajs/inertia-vue3'
import { InertiaProgress } from '@inertiajs/progress'
import { ZiggyVue } from 'ziggy';
import { Ziggy } from './ziggy';
import axios from 'axios'
import VueAxios from 'vue-axios'

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel'

createInertiaApp({
	title: (title) => `${title} - ${appName}`,
	resolve: (name) => require(`./Pages/${name}.vue`),
	setup ({ el, app, props, plugin }) {
		return createApp({ render: () => h(app, props) })
			.use(plugin)
			.use(VueAxios, axios)
			.use(ZiggyVue, Ziggy)
			.component('InertiaHead', Head)
		    .component('InertiaLink', Link)
			.mixin({ methods: { route } })
			.mount(el)
	}
})
InertiaProgress.init({ color: '#4B5563' })
