<template>
  <div class="grid grid-cols-1 gap-4 items-start lg:grid-cols-4 lg:gap-8">
    <div class="grid grid-cols-1 gap-4 lg:col-span-2">

      <section>
        <div class="rounded-lg bg-white overflow-hidden">
          <div class="bg-slate-50 p-6">
            <div class="flex flex-col gap-[10px] overflow-y-auto text-slate-400 text-sm">

              <div @click="createCache" 
              class="flex gap-[5px] cursor-pointer items-center text-lg text-sky-600 hover:text-sky-900"
              :class="{
                'pointer-events-none opacity-50': startCreateCache
              }">
                <PlayIcon class="h-6 w-6" aria-hidden="true" />
                Create cache
              </div>

              <div class="flex flex-col gap-[5px] max-h-[100px]" v-if="cacheResetList">

                <div class="flex justify-between" v-for="cacheItem in cacheResetList.items" :key="cacheItem">
                  <div v-if="cacheItem.filename">{{ cacheItem.filename }}</div>
                  <div v-if="cacheItem.timestamp">{{ cacheItem.timestamp }}</div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </section>

      <!-- Welcome panel -->
      <section aria-labelledby="profile-overview-title">
        <div class="rounded-lg bg-white overflow-hidden">
          <div class="bg-slate-50 p-6">
            <div class="sm:flex sm:items-center sm:justify-between">
              <div class="sm:flex sm:space-x-5">
                <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                  <p class="text-xl text-slate-500 sm:text-2xl">
                    {{ user.name }}
                  </p>
                  <p class="text-base text-slate-500 sm:text-lg">
                    {{ user.email }}
                  </p>
                </div>
              </div>
              <div class="mt-5 flex justify-center sm:mt-0">
                <a :href="route('profile.show')"
                  class="flex justify-center items-center px-4 py-2 border border-slate-300 shadow-sm text-sm font-medium rounded-md text-slate-600 bg-white hover:bg-slate-50">
                  Profile
                </a>
                <a href="/logout"
                  class="flex justify-center items-center px-4 py-2 ml-4 border border-red-300 shadow-sm text-sm font-medium rounded-md text-red-700 bg-white hover:bg-red-50">
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Actions panel -->
      <section aria-labelledby="quick-links-title">
        <div
          class="rounded-lg bg-slate-200 overflow-hidden shadow divide-y divide-slate-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
          <h2 class="sr-only" id="quick-links-title">Quick Links</h2>
          <div v-for="(action, actionIdx) in mainActions" :key="action.name" :class="[
            actionIdx === 0
              ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
              : '',
            actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
            actionIdx === mainActions.length - 2 ? 'sm:rounded-bl-lg' : '',
            actionIdx === mainActions.length - 1
              ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
              : '',
            'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500',
          ]">
            <div v-if="action.type !== 'empty'">
              <span :class="[
                action.iconBackground,
                action.iconForeground,
                'rounded-lg inline-flex p-3 ring-4 ring-white',
              ]">
                <component :is="action.icon" class="h-6 w-6" aria-hidden="true" />
              </span>
            </div>
            <div v-if="action.type !== 'empty'" class="mt-8">
              <h3 class="text-lg font-medium">
                <a :href="action.href" class="focus:outline-none">
                  <span class="absolute inset-0" aria-hidden="true" />
                  {{ action.name }}
                </a>
              </h3>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- Right column -->
    <div class="grid grid-cols-1 gap-4 lg:col-span-2" v-if="troubles">
      <div class="flex flex-row gap-[30px] text-sm">
        <div v-if="troubles.count.error > 0">📛 {{ troubles.count.error }}</div>
        <div v-if="troubles.count.warning > 0">
          ⚠️ {{ troubles.count.warning }}
        </div>
        <div v-if="troubles.count.notice > 0">
          💡 {{ troubles.count.notice }}
        </div>
      </div>
      <div class="flex flex-col gap-[5px] text-sm">
        <div v-for="trouble in troubles.items" :key="trouble.id">
          <div class="w-full flex flex-row gap-[10px]">
            <div v-if="trouble.type === 'warning'">⚠️</div>
            <div v-if="trouble.type === 'error'">📛</div>
            <div v-if="trouble.type === 'notice'">💡</div>
            <div v-html="trouble.name"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';

import {
  PuzzleIcon,
  LightningBoltIcon,
  BookmarkIcon,
  ViewBoardsIcon,
  UserGroupIcon,
  SpeakerphoneIcon,
  ChatAltIcon,
  RssIcon,
  StarIcon,
  ScaleIcon,
  TemplateIcon,
  AdjustmentsIcon,
  PaperAirplaneIcon,
  HeartIcon,
  UserAddIcon,
  ExternalLinkIcon,
  PlayIcon
} from '@heroicons/vue/outline';

import { usePage } from '@inertiajs/inertia-vue3';

const actions = [
  {
    icon: PuzzleIcon,
    name: 'Works',
    href: route('admin::works.index'),
    permission: [],
    iconForeground: 'text-orange-400',
    iconBackground: 'bg-orange-50',
  },
  {
    icon: LightningBoltIcon,
    name: 'Tags',
    href: route('admin::tags.index'),
    permission: [],
    iconForeground: 'text-yellow-400',
    iconBackground: 'bg-yellow-50',
  },
  {
    icon: BookmarkIcon,
    name: 'Presets',
    href: route('admin::work-item-presets.index'),
    permission: [],
    iconForeground: 'text-blue-400',
    iconBackground: 'bg-blue-50',
  },
  {
    icon: ViewBoardsIcon,
    name: 'Bundles',
    href: route('admin::bundles.index'),
    permission: [],
    iconForeground: 'text-green-400',
    iconBackground: 'bg-green-50',
  },
  {
    icon: UserGroupIcon,
    name: 'Teams',
    href: route('admin::team-members.index'),
    permission: [],
    iconForeground: 'text-purple-400',
    iconBackground: 'bg-purple-50',
  },
  {
    icon: SpeakerphoneIcon,
    name: 'Publications',
    href: route('admin::publications.index'),
    permission: [],
    iconForeground: 'text-red-400',
    iconBackground: 'bg-red-50',
  },
  {
    icon: ChatAltIcon,
    name: 'Newsletters',
    href: route('admin::newsletters.index'),
    permission: [],
    iconForeground: 'text-indigo-400',
    iconBackground: 'bg-indigo-50',
  },
  {
    icon: RssIcon,
    name: 'News',
    href: route('admin::news.index'),
    permission: [],
    iconForeground: 'text-pink-400',
    iconBackground: 'bg-pink-50',
  },
  {
    icon: StarIcon,
    name: 'Events',
    href: route('admin::events.index'),
    permission: [],
    iconForeground: 'text-teal-400',
    iconBackground: 'bg-teal-50',
  },
  {
    icon: ScaleIcon,
    name: 'Awards',
    href: route('admin::awards.index'),
    permission: [],
    iconForeground: 'text-slate-400',
    iconBackground: 'bg-slate-50',
  },
  {
    icon: TemplateIcon,
    name: 'Blocks',
    href: route('admin::blocks.index'),
    permission: [],
    iconForeground: 'text-yellow-500',
    iconBackground: 'bg-yellow-100',
  },
  {
    icon: AdjustmentsIcon,
    name: 'Settings',
    href: route('admin::settings.index'),
    permission: [],
    iconForeground: 'text-blue-500',
    iconBackground: 'bg-blue-100',
  },
  {
    icon: PaperAirplaneIcon,
    name: 'Pages',
    href: route('admin::pages.index'),
    permission: [],
    iconForeground: 'text-green-500',
    iconBackground: 'bg-green-100',
  },
  {
    icon: HeartIcon,
    name: 'Press kit',
    href: route('admin::press-kit-item.index'),
    permission: [],
    iconForeground: 'text-purple-500',
    iconBackground: 'bg-purple-100',
  },
  {
    icon: UserAddIcon,
    name: 'Vacancy',
    href: route('admin::vacancy-item.index'),
    permission: [],
    iconForeground: 'text-pink-500',
    iconBackground: 'bg-pink-100',
  },
  {
    icon: ExternalLinkIcon,
    name: 'Typology Figma',
    href: 'https://www.figma.com/file/SDAqMglAui4kjwGSuLTKwq/Shuka-%C2%B7-Cases?node-id=596%3A5583',
    permission: [],
    iconForeground: 'text-indigo-500',
    iconBackground: 'bg-indigo-100',
    target: '_blank',
  },
];

const user = computed(() => usePage().props.value.auth.user);

const hasAnyPermission = (permissions) => {
  return true;
  if (!permissions || !Array.isArray(permissions)) return false;
  const allPermissions = usePage().props.value.auth.can || {};
  return permissions.some((permission) => allPermissions[permission]);
};

const mainActions = ref(
  actions.filter((action) => hasAnyPermission(action.permission))
);

if (mainActions.value.length % 2 !== 0) {
  mainActions.value.push({ type: 'empty' });
}

const troubles = ref(null);

const getTroubles = async () => {
  try {
    const res = await fetch(`/admin/troubles`);
    if (!res.ok) throw new Error('Error fetching troubles');
    const data = await res.json();
    troubles.value = data;
  } catch (error) {
    console.error('Error fetching troubles:', error);
  }
};

const cacheResetList = ref(null);

const getCacheReset = async () => {
  try {
    const res = await fetch(`/admin/cache-reset-list`);
    if (!res.ok) throw new Error('Error fetching cache reset list');
    const data = await res.json();
    cacheResetList.value = data;
  } catch (error) {
    console.error('Error fetching cache reset list:', error);
  }
};

const startCreateCache = ref(false)

const createCache = async () => {
  try {
    const res = await fetch(`/admin/create-cache-s3-command`);
    if (!res.ok) throw new Error('Error');
    const data = await res.json();
    console.info('create-cache-s3-command', data);
    startCreateCache.value = true
  } catch (error) {
    console.error('Error', error);
  }
}

onMounted(() => {
  getTroubles();
  getCacheReset();
});
</script>
